<template>
  <div>
    <b-row>
      <b-col md="6">
        <b-card title="Sözleşme">
          <b-list-group>
            <b-list-group-item>
              <div class="text-primary font-weight-bold">
                Sözleşme No
              </div>
              <div>
                {{ dataItem.contract_no }}
              </div>
            </b-list-group-item>
            <b-list-group-item>
              <div class="text-primary font-weight-bold">
                Başlangıç Tarihi
              </div>
              <div>
                {{ moment(dataItem.sdate).format('LL') }}
              </div>
            </b-list-group-item>
            <b-list-group-item>
              <div class="text-primary font-weight-bold">
                Bitiş Tarihi
              </div>
              <div>
                {{ moment(dataItem.edate).format('LL') }}
              </div>
            </b-list-group-item>
            <b-list-group-item>
              <div class="text-primary font-weight-bold">
                Sözleşme Tarihi
              </div>
              <div>
                {{ dataItem.contract_date? moment(dataItem.contract_date).format('LL') : '-' }}
              </div>
            </b-list-group-item>
            <b-list-group-item>
              <div class="text-primary font-weight-bold">
                Sözleşme Dosyası
              </div>
              <div>
                <b-button
                  v-if="dataItem.file"
                  variant="success"
                  size="sm"
                  block
                  :href="baseURL + '/media/rentals/' + dataItem.file"
                  target="_blank"
                  class="mt-1"
                  :disabled="!dataItem.file"
                >
                  <FeatherIcon
                    icon="DownloadIcon"
                  />
                  Sözleşme İndir
                </b-button>
                <b-button
                  v-else
                  variant="danger"
                  size="sm"
                  block
                  @click="openUploadModal"
                >
                  <FeatherIcon
                    icon="UploadIcon"
                  />
                  Sözleşme Yükle
                </b-button>
              </div>
            </b-list-group-item>
            <b-list-group-item>
              <div class="text-primary font-weight-bold">
                Sözleşme Durumu
              </div>
              <div>
                {{ dataItem.contract_status }}
              </div>
            </b-list-group-item>
            <b-list-group-item>
              <div class="text-primary font-weight-bold">
                Araç Stok Durumu
              </div>
              <div>
                {{ dataItem.stock_status }}
              </div>
            </b-list-group-item>
            <b-list-group-item v-if="dataItem.notes">
              <div class="text-primary font-weight-bold">
                Notlar
              </div>
              <div>
                {{ dataItem.notes }}
              </div>
            </b-list-group-item>
          </b-list-group>
          <div class="mt-1 text-center">
            <b-button
              variant="flat-danger"
              :to="'/rental/contracts/edit/' + dataItem.id"
            >
              <FeatherIcon icon="EditIcon" />
              Sözleşmeyi Güncelle
            </b-button>
          </div>
        </b-card>
      </b-col>
      <b-col md="6">
        <b-card
          no-body
        >
          <b-card-header>
            <b-card-title>Sözleşme Aracı</b-card-title>
          </b-card-header>
          <rental-stock />
        </b-card>
      </b-col>
    </b-row>
    <b-modal
      v-model="uploadContract"
      centered
      title="Sözleşme Yükle"
      hide-footer
      no-close-on-backdrop
      no-close-on-esc
    >
      <b-form-group
        label="Sözleşme Dosyası"
        label-for="upload_file"
      >
        <b-form-file
          id="upload_file"
          v-model="upload_file"
          placeholder="Bir dosya seçin veya buraya bırakın..."
          drop-placeholder="Dosya buraya bırakın..."
          browse-text="Dosya Seçin"
        />
      </b-form-group>
      <div class="text-center">
        <b-button
          variant="success"
          :disabled="!upload_file"
          @click="uploadContractFile"
        >
          <FeatherIcon icon="UploadIcon" /> Dosya Yükle
        </b-button>
      </div>
    </b-modal>
  </div>
</template>

<script>
import {
  BRow, BCol, BCard, BCardHeader, BCardTitle, BListGroup, BListGroupItem, BButton, BModal, BFormGroup, BFormFile,
} from 'bootstrap-vue'
import RentalStock from '@/views/Rental/Contracts/elements/RentalStock.vue'

export default {
  name: 'ContractForm',
  components: {
    BRow,
    BCol,
    BCard,
    BCardHeader,
    BCardTitle,
    BListGroup,
    BListGroupItem,
    BButton,
    BModal,
    BFormGroup,
    BFormFile,
    RentalStock,
  },
  props: {
    refreshData: {
      type: Function,
      required: false,
    },
  },
  data() {
    return {
      baseURL: this.$store.state.app.baseURL,
      uploadContract: false,
      upload_file: null,
    }
  },
  computed: {
    dataItem() {
      return this.$store.getters['rentalContracts/dataItem']
    },
  },
  methods: {
    openUploadModal() {
      this.uploadContract = true
    },
    uploadContractFile() {
      this.$store.dispatch('rentalContracts/uploadContractFile', {
        id: this.$route.params.id,
        upload_file: this.upload_file,
      })
        .then(response => {
          if (response) {
            this.refreshData()
            this.upload_file = null
            this.uploadContract = false
          }
        })
    },
  },
}
</script>
